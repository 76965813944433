import { useEffect, useState } from "react";

import Slide from "./Posters/Slide";

const slideDuration = 3000;

interface Props {
  posters: Poster[];
  url: string;
}

interface SlidesState {
  index: number;
  prevIndex: number | null;
}

function postersToSlides(posters: Poster[]) {
  const slides: PosterSlide[] = [];
  posters.forEach((p) => {
    p.poster_images.forEach((pi) => {
      slides.push({
        artist: p.artist,
        title: p.title,
        dates: p.dates,
        dark_background: p.dark_background,
        text_alignment: p.text_alignment,
        ...pi
      });
    });
  });
  return slides;
}

function setInverted(inverted) {
  const body = document.querySelector("body");
  if (inverted) {
    body.classList.add("inverted");
  } else {
    body.classList.remove("inverted");
  }
}

function useSlides(slides: PosterSlide[]) {
  const [state, setState] = useState<SlidesState>({
    index: 0,
    prevIndex: null
  });

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setState({
        prevIndex: state.index,
        index: (state.index + 1) % slides.length
      });
    }, slideDuration);
    return () => {
      clearInterval(slideInterval);
    };
  });

  return [
    slides[state.index],
    state.prevIndex !== null && slides[state.prevIndex],
    slides[(state.index + 1) % slides.length]
  ];
}

export default function Posters(props: Props) {
  const slides = postersToSlides(props.posters);

  const [slide, prevSlide, nextSlide] = useSlides(slides);

  useEffect(() => {
    setInverted(slide.dark_background);
    return () => {
      setInverted(false);
    };
  }, [slide.dark_background]);

  return (
    <a href={props.url} className={"posters align-" + slide.text_alignment}>
      {prevSlide && (
        <Slide key={prevSlide.id} slide={prevSlide} className="previous" />
      )}
      <Slide key={slide.id} slide={slide} className="current" />
      <Slide key={nextSlide.id} slide={nextSlide} className="next" />
      <div className="content">
        <div className="inner">
          <div className="text">
            {slide.artist && (
              <div className="exhibition-artists">{slide.artist}</div>
            )}
            {slide.title && (
              <div className="exhibition-name">{slide.title}</div>
            )}
            {slide.dates && (
              <div className="exhibition-dates">{slide.dates}</div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
}
