// Entry point for the build script in your package.json

// Responsive embeds
import responsiveEmbeds from "./frontend/responsiveEmbeds";
responsiveEmbeds();

// Grid overlay
import gridOverlay from "./frontend/gridOverlay";
gridOverlay();

// Scroll to anchors
import startScrollToAnchors from "./frontend/ScrollToAnchors";
startScrollToAnchors();

// Stimulus
import { Application } from "stimulus";
import HeaderController from "./controllers/HeaderController";
const application = Application.start();
application.register("header", HeaderController);

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

// External links
import { startExternalLinks } from "./lib/externalLinks";
startExternalLinks();
