import { useState } from "react";

export default function useLimit<T>(
  items: T[],
  limit: number
): [T[], boolean, (expanded: boolean) => void] {
  const [expanded, setExpanded] = useState(false);

  const limited = !expanded && items.length > limit;

  let limitedItems = items;
  if (limited) {
    limitedItems = items.slice(0, limit);
  }

  return [limitedItems, limited, setExpanded];
}
