import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

elementScrollIntoViewPolyfill();

function readyHandler(fn: () => void) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

function handleScroll(evt: Event) {
  const link = evt.target as HTMLAnchorElement;
  const anchor = link.getAttribute("href").replace(/^#/, "");
  const target = document.querySelector(`a[name="${anchor}"]`);
  if (target) {
    evt.preventDefault();
    target.scrollIntoView({ behavior: "smooth" });
  }
}

export default function startScrollToAnchors() {
  readyHandler(() => {
    Array.from(document.querySelectorAll("a")).forEach((link) => {
      const href = link.getAttribute("href");
      if (href && href.match(/^#/)) {
        link.addEventListener("click", handleScroll);
      }
    });
  });
}
