import Picture from "../Picture";

interface Props {
  item: InstallationView;
  url: string;
  showItem: (item: InstallationView) => void;
}

export default function Item(props: Props) {
  const { item } = props;

  const handleClick = (evt: React.MouseEvent) => {
    if (!evt.ctrlKey && !evt.metaKey) {
      evt.preventDefault();
      props.showItem(item);
    }
  };

  return (
    <a href={props.url} className="item" onClick={handleClick}>
      <div className="image">
        <Picture image={item.thumbnail} sizes="(max-width: 900px) 50vw, 33vw" />
      </div>
    </a>
  );
}
