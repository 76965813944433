import { useState } from "react";

import Picture from "../Picture";

interface Props {
  slide: PosterSlide;
  className: string;
}

export default function Slide(props: Props) {
  const [loaded, setLoaded] = useState(false);
  const handleLoaded = () => setLoaded(true);

  return (
    <div
      className={
        "slide " + props.className + (loaded ? " loaded" : " loading")
      }>
      <Picture
        key={props.slide.id}
        image={props.slide.image}
        sizes="100vw"
        onLoad={handleLoaded}
      />
    </div>
  );
}
