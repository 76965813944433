import useLightbox from "../hooks/useLightbox";
import useLimit from "../hooks/useLimit";
import Lightbox from "./Lightbox";
import Modal from "./Modal";
import Work from "./WorksGrid/Work";
import WorkTitle from "./WorksGrid/WorkTitle";

interface Props {
  works: Work[];
}

export default function WorksGrid(props: Props) {
  const [limitedWorks, limited, setExpanded] = useLimit(props.works, 8);

  const workUrl = (work: Work) => {
    return `#works/${work.id}`;
  };

  const [shownWork, setShownWork, navigate] = useLightbox(props.works, workUrl);

  const closeModal = (evt?: React.MouseEvent) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setShownWork(null);
  };

  const handleExpand = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setExpanded(true);
  };

  return (
    <div className="works-grid">
      {shownWork && (
        <Modal onClose={closeModal}>
          <Lightbox
            image={shownWork.image}
            videoUrl={shownWork.video_url}
            navigate={navigate}>
            <WorkTitle work={shownWork} />
          </Lightbox>
        </Modal>
      )}
      <div className="works">
        {limitedWorks.map((w) => (
          <Work key={w.id} work={w} showWork={setShownWork} url={workUrl(w)} />
        ))}
      </div>
      {limited && (
        <div className="buttons">
          <button className="view-more" onClick={handleExpand}>
            + View more
          </button>
        </div>
      )}
    </div>
  );
}
