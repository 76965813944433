import Picture from "../Picture";
import WorkTitle from "./WorkTitle";

interface Props {
  work: Work;
  url: string;
  showWork: (work: Work) => void;
}

export default function Work(props: Props) {
  const { work } = props;

  const handleClick = (evt: React.MouseEvent) => {
    if (!evt.ctrlKey && !evt.metaKey) {
      evt.preventDefault();
      props.showWork(work);
    }
  };

  return (
    <a href={props.url} className="work" onClick={handleClick}>
      <div className="image">
        <Picture image={work.image} sizes="(max-width: 900px) 50vw, 25vw" />
      </div>
      <div className="info">
        <WorkTitle work={work} />
      </div>
    </a>
  );
}
